:root {
  --color-black: #000000;
  --color-white: #ffffff;
  --color-dark: #252a31;
  --color-light: #dce3e9;
  --color-yellow: #eba52d;
  --color-dark-yellow: #805a19;
}

html {
  background-color: var(--color-black);
  color: var(--color-white);
}

body {
  font-family: 'DM Sans', sans-serif;
  font-optical-sizing: auto;
}

/* button */

.button {
  background-color: var(--color-white);
  color: var(--color-dark);
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 24px;
}

.button.is-active {
  background-color: var(--color-yellow);
}

/* App */

.app {
  display: flex;
  height: 100dvh;
  width: 100vw;
  overflow: hidden;
  position: relative;
}

.app::before {
  content: '';
  position: absolute;
  top: -380px;
  left: calc(50% - 350px);
  width: 700px;
  height: 700px;
  background: rgb(154, 154, 154);
  background: radial-gradient(
    circle,
    rgba(154, 154, 154, 1) 0%,
    rgba(154, 154, 154, 0) 70%,
    rgba(154, 154, 154, 0) 100%
  );
  transform: scale(2.2, 1);
  opacity: 0.8;
  transition: all 0.6s ease-in-out;
  z-index: 10;
  pointer-events: none;
}

.app.has-sidebar::before {
  content: '';
  position: absolute;
  top: -350px;
  left: -350px;
  width: 700px;
  height: 700px;
  background: rgb(154, 154, 154);
  background: radial-gradient(
    circle,
    rgba(154, 154, 154, 1) 0%,
    rgba(154, 154, 154, 0) 70%,
    rgba(154, 154, 154, 0) 100%
  );
  transform: scale(1, 1);
  opacity: 1;
}

/* Header */

.app-header {
  height: 100dvh;
  width: 215px;
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
}

/* Navbar */

.navbar-wrapper {
  position: relative;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.navbar-wrapper.is-active {
  opacity: 1;
}

.navbar-button {
  position: absolute;
  top: calc(50% - 30px);
  left: 7px;
  width: 60px;
  height: 60px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  overflow: hidden;
  text-indent: -9999px;
  z-index: 11;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;
}

.navbar-button::before {
  content: '';
  position: absolute;
  top: 3px;
  left: 23px;
  width: 3px;
  height: 54px;
  background: var(--color-dark-yellow);
  border-radius: 2px;
}

.navbar-button::after {
  content: '';
  position: absolute;
  top: 10px;
  right: 23px;
  width: 3px;
  height: 40px;
  background: var(--color-dark-yellow);
  border-radius: 2px;
}

.navbar-button.is-active {
  opacity: 1;
  pointer-events: all;
}

.navbar {
  position: relative;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  pointer-events: none;
}

.navbar.is-active {
  opacity: 1;
  pointer-events: all;
}

.navbar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbar li {
  margin-bottom: 4px;
}

.navbar-link,
.info-link {
  position: relative;
  padding: 10px 28px;
  display: block;
  color: var(--color-yellow);
  font-weight: 500;
  font-size: 23px;
  background-color: transparent;
  border: none;
}

.navbar-link::before {
  opacity: 0;
  content: '';
  position: absolute;
  top: -44px;
  left: 8px;
  width: 135px;
  height: 135px;
  background: rgb(235, 165, 5);
  background: radial-gradient(
    circle,
    rgba(235, 165, 45, 1) 0%,
    rgba(235, 165, 45, 0) 70%,
    rgba(235, 165, 45, 0) 100%
  );
  transform: scale(2, 0.5);
  transition: opacity 0.3s ease-in-out;
}

.navbar-link.is-active::before {
  opacity: 0.3;
}

/* Info */

.info-container {
  z-index: 1;
  position: absolute;
  left: 0;
  top: -490px;
  display: flex;
  flex-direction: column;
  transition: top 0.5s ease-in-out;
}

.info-container.is-active {
  top: 0;
}

.info-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 41px;
  width: 3px;
  height: calc(100% - 30px);
  background: var(--color-light);
  z-index: -1;
}

.info-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 41px;
  width: 3px;
  height: 60px;
  background: var(--color-yellow);
  z-index: -1;
  transition: height 0.5s ease-in-out;
}

.info-container.is-state-2::after {
  height: 156px;
}

.info-container.is-state-3::after {
  height: 252px;
}

.info-container.is-state-4::after {
  height: 348px;
}

.info-container.is-state-5::after {
  height: 444px;
}

.info-container.is-state-6::after {
  height: 540px;
}

.info-link {
  padding-left: 72px;
  position: relative;
  margin-top: -46px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  pointer-events: none;
}

.info-link.is-active {
  opacity: 1;
  pointer-events: all;
}

.info-link::before {
  content: '';
  position: absolute;
  top: calc(50% - 13px);
  left: 30px;
  width: 26px;
  height: 26px;
  background-image: url('/public/intro.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.question-link {
  border: none;
  background-color: transparent;
  margin-top: 50px;
  padding: 10px 30px 10px 30px;
  width: 82px;
}

.question-link span {
  display: block;
  background-color: var(--color-light);
  color: var(--color-dark);
  border-radius: 50%;
  text-align: center;
  line-height: 26px;
  font-size: 20px;
  font-size: 17px;
  font-weight: 500;
  width: 26px;
  height: 26px;
}

.question-link:first-child {
  margin-top: 40px;
}

.question-link.is-active span {
  background-color: var(--color-yellow);
  color: var(--color-white);
}

.question-link.is-finished span {
  background-color: var(--color-yellow);
  overflow: hidden;
  text-indent: -9999px;
  position: relative;
  background-image: url('/public/icon-check.svg');
  background-repeat: no-repeat;
  background-position: center;
}

/* Main */

.app-main {
  height: 100dvh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-main.has-sidebar {
  width: calc(100vw - 215px);
  padding-right: 46px;
}

/* Logo */

.logo-page {
  height: 100dvh;
  width: 100vw;
}

.logo-wrapper {
  width: 100%;
  height: 100%;
}

.logo-screensaver {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
}

.logo-revolt {
  position: absolute;
  top: 15%;
  left: 15%;
  width: 70%;
  height: 70%;
  background-position: center;
  background-repeat: no-repeat;
}

.logo-1 {
  background-image: url('/public/logo/logo.svg');
  background-size: 90%;
}

.logo-2 {
  background-image: url('/public/logo/logo-glow.svg');
  opacity: 0;
  animation: fadeInOut 3s infinite;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

/* Intro */

.intro-page {
  width: 100%;
  height: 100dvh;
  position: relative;
  left: -122px;
  z-index: 11;
  padding-top: 32px;
}

.intro-page::after {
  content: '';
  position: absolute;
  right: -128px;
  bottom: 40px;
  width: 141px;
  height: 38px;
  background-image: url('/public/logo.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.question {
  pointer-events: none;
}

.question-title {
  font-size: 58px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 20px;
  color: var(--color-yellow);
  overflow: hidden;
  position: relative;
  max-height: 0;
  transition: all 0.5s ease-in-out;
}

.question-answers {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.question.is-active {
  pointer-events: all;
}

.question.is-active .question-title {
  max-height: 100px;
}

.question.is-answered {
  margin-bottom: 34px;
  pointer-events: all;
}

.question .button {
  position: relative;
  top: -10px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  transition-property: opacity, top, max-width, padding, margin-right;
  pointer-events: none;
  overflow: hidden;
  max-width: 0;
  padding: 0;
  height: 43px;
}

.question.is-active .button {
  opacity: 1;
  pointer-events: all;
  top: 0;
  max-width: 300px;
  padding: 10px 20px;
}

.question.is-answered .button.is-active {
  opacity: 1;
  pointer-events: all;
  top: -10px;
  max-width: 300px;
  padding: 10px 20px;
}

.question.is-active .button:not(:last-child),
.question.is-answered .button.is-active:not(:last-child) {
  margin-right: 32px;
}

/* Reports */

.report-page {
  position: relative;
  width: 100%;
}

.report-wrapper {
  width: 100%;
}

.report-container {
  max-height: 90dvh;
  overflow: hidden;
  overflow-y: auto;
  padding-right: 22px;
}

.report-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
  padding-bottom: 100px;
  padding-top: 100px;
}

.report-item {
}

.report-card {
  background-color: transparent;
  border: none;
  padding: 0;
  max-width: 100%;
  width: 100%;
}

.report-card-title {
  color: var(--color-white);
  font-size: 16px;
  min-height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
}

.report-card-image {
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  border: 2px solid var(--color-yellow);
  display: flex;
  padding-bottom: 55%;
  width: 100%;
}

.report-card-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.report-detail {
  width: 100%;
  min-height: 80dvh;
}

.report-detail-title {
}

.report-detail-code {
  height: calc(80vh - 10px);
  overflow: hidden;
  border-radius: 15px;
  position: relative;
  z-index: 1;
}

.report-detail-code > * {
  width: 100%;
  height: calc(80vh - 10px) !important;
}

.report-detail-code iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.report-detail-code .tableauPlaceholder div div:last-child {
  background-size: cover !important;
}

.report-detail-gallery {
  padding-right: 46px;
}

.report-detail-gallery img {
  width: 100%;
  height: auto;
}

.report-detail-gallery-item {
  padding-bottom: 70px;
}

/* References */

.references-page {
  position: relative;
  width: 100%;
}

.reference-wrapper {
  width: 100%;
  position: relative;
}

.reference-list {
}

.reference-item {
  padding-right: 22px;
  padding-top: 80px;
  padding-bottom: 100px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  min-height: 600px;
}

.reference-card {
  position: relative;
  max-width: 820px;
  padding: 70px 18px 16px 38px;
  display: flex;
  font-size: 17px;
  border-radius: 10px;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(24, 24, 24, 1) 100%
  );
}

.reference-card::after {
  content: '';
  position: absolute;
  top: -62px;
  right: 16px;
  width: 122px;
  height: 109px;
  background-image: url('/public/quotes.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.reference-image {
  width: 104px;
}

.reference-image div {
  position: relative;
  width: 104px;
  height: 104px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-white);
}

.reference-image img {
  max-width: 100%;
  height: auto;
}

.reference-body {
  padding-left: 44px;
  width: calc(100% - 104px);
}

.reference-text {
  padding-right: 80px;
  margin-bottom: 35px;
}

.reference-title {
  text-align: right;
}

/* Use cases */

.use-case-page {
  width: 100%;
}

.use-case-page::after {
  content: '';
  position: absolute;
  right: 40px;
  bottom: 40px;
  width: 141px;
  height: 38px;
  background-image: url('/public/logo.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.use-case-wrapper {
  padding-top: 0;
  padding-bottom: 40px;
  width: 100%;
  position: relative;
}

.use-case-item {
  padding-right: 22px;
  padding-top: 50px;
  padding-bottom: 70px;
}

.use-case-card {
  position: relative;
  max-width: 1050px;
  padding: 60px 220px 40px 60px;
  margin-top: 50px;
  display: flex;
  font-size: 17px;
  border-radius: 10px;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(24, 24, 24, 1) 100%
  );
}

.use-case-logo {
  position: absolute;
  right: -24px;
  top: -58px;
  max-width: 172px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.use-case-logo img {
  width: 100%;
  height: auto;
}

.use-case-body {
}

.use-case-title {
  font-size: 34px;
  font-weight: 700;
  margin-bottom: 20px;
}

.use-case-text h2 {
  font-size: 23px;
  font-weight: 700;
  margin-bottom: 8px;
}

.use-case-text p {
  margin-bottom: 34px;
  font-size: 17px;
}

/* Contact */

.contact {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 46px;
}

/* Overflow */

.report-wrapper.is-overflowing::after,
.report-wrapper.is-overflowing::before,
.report-container.is-overflowing::after,
.report-container.is-overflowing::before {
  content: '';
  position: absolute;
  left: 0;
  width: calc(100% - 22px);
  height: 100px;
}

.report-container.is-overflowing::after,
.report-container.is-overflowing::before {
  bottom: -1px;
}

.report-wrapper.is-overflowing::after,
.report-wrapper.is-overflowing::before {
  top: -1px;
}
.report-container.is-overflowing::after {
  backdrop-filter: blur(6px);
  mask-image: linear-gradient(0deg, rgba(0, 0, 0, 1), transparent 100%);
}

.report-wrapper.is-overflowing::after {
  backdrop-filter: blur(6px);
  mask-image: linear-gradient(180deg, rgba(0, 0, 0, 1), transparent 100%);
}

.report-container.is-overflowing::before {
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 1;
}

.report-wrapper.is-overflowing::before {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: 1;
}

/* Swiper */

.swiper-pagination {
  bottom: 15px !important;
}

.swiper-pagination-bullet {
  width: 24px !important;
  height: 24px !important;
  background-color: var(--color-black) !important;
  border: 4px solid var(--color-yellow) !important;
  transition: all 0.3s ease-in-out;
  margin: 0 4px !important;
  border-radius: 50% !important;
  opacity: 1 !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  position: relative;
}

.swiper-pagination-bullet::before {
  opacity: 0;
  content: '';
  position: absolute;
  top: -27px;
  left: -27px;
  width: 70px;
  height: 70px;
  background: rgb(235, 165, 5);
  background: radial-gradient(
    circle,
    rgba(235, 165, 45, 1) 0%,
    rgba(235, 165, 45, 0) 70%,
    rgba(235, 165, 45, 0) 100%
  );
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
}

.swiper-pagination-bullet-active {
  background-color: var(--color-yellow) !important;
}

.swiper-pagination-bullet-active::before {
  opacity: 0.6;
}

/* Fade animation */

.fade-enter,
.fade-enter-active,
.fade-exit,
.fade-exit-active {
  width: 100%;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active,
.fade-exit-active {
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
}

/* Page loading */

.loading-wrapper {
  position: relative;
}

.page-loading-wrapper {
  position: absolute;
  width: calc(100% + 2px);
  height: 100%;
  top: 0;
  left: -1px;
  background-color: var(--color-black);
  z-index: 2;
}

.page-loading {
  position: absolute;
  width: 280px;
  height: 346px;
  left: calc(50% - 140px);
  top: calc(50% - 173px);
  background-image: url('/public/loading-bg.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.page-loading-01,
.page-loading-02,
.page-loading-03,
.page-loading-04,
.page-loading-05 {
  position: absolute;
  background-color: #ef9e14;
  width: 1px;
}

.page-loading-01::before,
.page-loading-01::after,
.page-loading-02::before,
.page-loading-02::after,
.page-loading-03::before,
.page-loading-03::after,
.page-loading-04::before,
.page-loading-04::after,
.page-loading-05::before,
.page-loading-05::after {
  content: '';
  position: absolute;
}

.page-loading-01::after,
.page-loading-02::after,
.page-loading-03::after,
.page-loading-05::after {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.page-loading-01 {
  height: 114px;
  left: 20px;
  top: 61px;
  animation: loading1 1.5s linear infinite;
}

@keyframes loading1 {
  0% {
    height: 114px;
    top: 61px;
  }
  30% {
    height: 98px;
    top: 88px;
  }
  50% {
    height: 98px;
    top: 88px;
  }
  70% {
    height: 114px;
    top: 61px;
  }
  100% {
    height: 114px;
    top: 61px;
  }
}

.page-loading-01::before {
  left: -6px;
  top: -8px;
  width: 13px;
  height: 13px;
  background-color: #ffbc10;
  border-radius: 50%;
}

.page-loading-01::after {
  bottom: -31px;
  left: -15px;
  width: 31px;
  height: 31px;
  background-image: url('/public/loading-bg-1.svg');
}

.page-loading-02 {
  height: 111px;
  left: 76px;
  top: 98px;
  animation: loading2 1.5s linear infinite;
}

@keyframes loading2 {
  0% {
    height: 111px;
    top: 98px;
  }
  30% {
    height: 73px;
    top: 136px;
  }
  50% {
    height: 73px;
    top: 136px;
  }
  80% {
    height: 111px;
    top: 98px;
  }
  100% {
    height: 111px;
    top: 98px;
  }
}

.page-loading-02::before {
  left: -9px;
  top: -9px;
  width: 19px;
  height: 19px;
  background-color: #eba52d;
  border-radius: 50%;
}

.page-loading-02::after {
  bottom: -15px;
  left: -7px;
  width: 15px;
  height: 15px;
  background-image: url('/public/loading-bg-2.svg');
}

.page-loading-03 {
  height: 184px;
  left: 136px;
  top: 89px;
  animation: loading3 1.5s linear infinite;
}

@keyframes loading3 {
  0% {
    height: 184px;
    top: 89px;
  }
  30% {
    height: 135px;
    top: 125px;
  }
  50% {
    height: 135px;
    top: 125px;
  }
  80% {
    height: 184px;
    top: 89px;
  }
  100% {
    height: 184px;
    top: 89px;
  }
}

.page-loading-03::before {
  left: -8px;
  top: -11px;
  width: 17px;
  height: 17px;
  background-color: #f7f74b;
  border-radius: 50%;
}

.page-loading-03::after {
  bottom: -37px;
  left: -18px;
  width: 37px;
  height: 37px;
  background-image: url('/public/loading-bg-3.svg');
}

.page-loading-04 {
  height: 109px;
  left: 196px;
  top: 141px;
  animation: loading4 1.5s linear infinite;
}

@keyframes loading4 {
  0% {
    height: 109px;
    top: 141px;
  }
  30% {
    height: 92px;
    top: 169px;
  }
  50% {
    height: 92px;
    top: 169px;
  }
  80% {
    height: 109px;
    top: 141px;
  }
  100% {
    height: 109px;
    top: 141px;
  }
}

.page-loading-04::before {
  left: -6px;
  top: -12px;
  width: 13px;
  height: 13px;
  background-color: #ffbc10;
  border-radius: 50%;
}

.page-loading-04::after {
  left: -6px;
  bottom: -13px;
  width: 13px;
  height: 13px;
  background-color: #eba52d;
  border-radius: 50%;
}

.page-loading-05 {
  height: 105px;
  left: 254px;
  top: 105px;
  animation: loading5 1.5s linear infinite;
}

@keyframes loading5 {
  0% {
    height: 105px;
    top: 105px;
  }
  30% {
    height: 99px;
    top: 122px;
  }
  50% {
    height: 99px;
    top: 122px;
  }
  80% {
    height: 105px;
    top: 105px;
  }
  100% {
    height: 105px;
    top: 105px;
  }
}

.page-loading-05::before {
  left: -4px;
  top: -2px;
  width: 9px;
  height: 9px;
  background-color: #eba52d;
  border-radius: 50%;
}

.page-loading-05::after {
  bottom: -30px;
  left: -14px;
  width: 30px;
  height: 30px;
  background-image: url('/public/loading-bg-5.svg');
}
